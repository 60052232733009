import React from 'react';
import styled from 'styled-components';
import Layout from '@/components/layout';
import { Metadata } from '@latitude/metadata';
import { SecondaryHero } from '@latitude/hero';
import { Flex } from '@latitude/box';
import {
  BREAKPOINT,
  FLEX_WRAP,
  MARGIN,
  COLOR
} from '@latitude/core/utils/constants';

import FormLists from './components/_formLists';

const FormsPage = props => (
  <Layout location={props.location}>
    <Main className="navigation-spacer">
      <Metadata
        title="Useful Forms | Gem by Latitude"
        description="Find useful forms for Gem credit cards and personal loans here."
        canonical="/forms/"
      />

      <SecondaryHero
        heading={
          <>
            Useful card related <BrDesktop /> forms
          </>
        }
      />

      <Flex
        isResponsive
        flexWrap={FLEX_WRAP.WRAP}
        margin={`0px ${MARGIN.MAUTO}`}
        padding="72px 0 42px"
      >
        <FormLists />
      </Flex>
    </Main>
  </Layout>
);

const Main = styled.main`
  background-color: #f8f8f8;
  h1 {
    color: ${COLOR.BLACK} !important;
  }
`;

const BrDesktop = styled.br`
  display: none;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
  }
`;

export default FormsPage;
