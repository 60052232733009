/* eslint-disable import/no-dynamic-require */
import React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { COLOR, BREAKPOINT } from '@latitude/core/utils/constants';
import CardTile from '../../contact-us/components/_CardTile';
import formData from '../data/forms.json';

export default () => {
  const {
    thirdPartyAuthority,
    directDebitRequest,
    identityVerification,
    conditionsOfUse,
    additionalCardHolder,
    automaticPayment,
    balanceTransfer,
    debitCardServiceAgreement
  } = formData;

  const cardData = addTracking([
    {
      id: 'gem-visa-forms',
      title: 'Gem Visa',
      image: 'gem-visa-card.png',
      cardImageOrientation: 'vertical',
      links: [
        conditionsOfUse.gemVisa,
        automaticPayment.gemVisa,
        directDebitRequest.gemVisa,
        identityVerification.gemVisa,
        thirdPartyAuthority.gemVisa,
        additionalCardHolder.gemVisa,
        debitCardServiceAgreement.gemVisa
      ]
    },
    {
      id: 'gem-visa-essential-forms',
      title: 'Gem Essential',
      image: 'gem-essential-visa-card.png',
      cardImageOrientation: 'vertical',
      links: [
        conditionsOfUse.gemEssential,
        automaticPayment.gemEssential,
        directDebitRequest.gemEssential,
        balanceTransfer.gemEssential,
        identityVerification.gemEssential,
        thirdPartyAuthority.gemEssential,
        additionalCardHolder.gemEssential,
        debitCardServiceAgreement.gemEssential
      ]
    },
    {
      id: 'gem-credit-line-forms',
      title: 'Gem CreditLine',
      image: 'gem-creditline-nz.png',
      cardImageOrientation: 'horizontal',
      links: [
        conditionsOfUse.gemCreditLine,
        automaticPayment.gemCreditLine,
        directDebitRequest.gemCreditLine,
        thirdPartyAuthority.gemCreditLine,
        debitCardServiceAgreement.gemCreditLine
      ]
    },
    {
      id: 'gem-personal-loans-forms',
      title: 'Gem Personal Loans',
      image: 'gem-by-latitude-logo-blue3x.png',
      cardImageOrientation: 'horizontal',
      links: [
        conditionsOfUse.gemPersonalLoans,
        directDebitRequest.gemPersonalLoans,
        automaticPayment.gemPersonalLoans,
        identityVerification.gemPersonalLoans,
        thirdPartyAuthority.gemPersonalLoans
      ]
    }
  ]);

  function addTracking(data) {
    return data.map(({ links, ...restItem }) => {
      const linksWithTracking = links.map(link => ({
          ...link
        }));

      return {
        ...restItem,
        links: linksWithTracking
      };
    });
  }

  const Ul = styled.ul`
    width: 100%;
    min-width: 333px;
    list-style-type: none;
    margin: 0;
    padding: 24px;
  `;

  const Li = styled.li`
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 8px;
    }
    a {
      color: ${COLOR.BLUE_DEEP} !important;
      border-color: ${COLOR.BLUE_DEEP} !important;
      font-weight: 300;
    }
  `;

  return (
    <Group>
      {cardData.map(card => (
        <GroupItem>
          <StyledCardTile
            key={card.title}
            image={
              <img
                src={require(`../../../images/credit-cards/${card.image}`)}
                alt={card.title}
              />
              }
            cardImageOrientation={card.cardImageOrientation}
            title={card.title}
            content={
              <Ul>
                {card.links.map(link => (
                  <Li key={link.title}>
                    <Link
                      {...link}
                      trackProductId={card.productid}
                      trackEventData={{
                          location: card.title.replace(/(°|<([^]+)>)/gi, '')
                        }}
                    >
                      {link.title}
                    </Link>
                  </Li>
                  ))}
              </Ul>
              }
          />
        </GroupItem>
        ))}
    </Group>
  );
};

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${BREAKPOINT.MD}) {
    justify-content: space-between;
  }
`;

const GroupItem = styled.div`
  width: 100%;
  max-width: 350px;

  @media (min-width: ${BREAKPOINT.MD}) {
    flex: 0 0 calc(50% - 15px);
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    flex: 0 0 calc(33.333333% - 15px);
  }
`;

const StyledCardTile = styled(CardTile)`
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
`;
